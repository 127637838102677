import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get, head } from '../../lib/nodash';

import ButtonAddToCart from '../ButtonAddToCart';
import { getVariantPrice } from '../../lib/product';

const DynamicProductAddToCartButton = ({
  product,
  label,
  showPrice,
  size = 'medium',
  ...rest
}) => {
  const variant = head(get('item.variants', product));
  const finalLabel = showPrice ? `${getVariantPrice(variant)} ${label}` : label;

  return get('item.variants', product) ? (
    <ButtonAddToCart
      size={size}
      variantId={get('shopifyId', variant)}
      label={finalLabel}
      {...rest}
    />
  ) : null;
};

DynamicProductAddToCartButton.propTypes = {
  product: PropTypes.object,
  label: PropTypes.string,
  showPrice: PropTypes.bool,
  size: PropTypes.string,
};

export default memo(DynamicProductAddToCartButton);
